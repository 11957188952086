// 3rd party
import { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

// Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { enableContent2BlocksPreview } from '@/lib/contentful/module'
import { NextImage } from '../Image/NextImage'

// Components
import CTA from '@/components/CTA'
import Markdown from '@/components/atoms/Markdown'
import { default as pagePaths } from '../../public/promotion-urls.json'
import useIsInViewport from '@/hooks/useIntersection'
import {
  contentClick,
  selectContentPromotionEvent,
  viewContentPromotionEvent,
} from '@/lib/ga-events'
import { getContentModel } from '@/lib/helper'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import styleConfig from '@/styles/style-config'
const DynamicVideoPlayer = dynamic(() => import('@/components/VideoPlayer/SimpleVideoPlayer'), {
  loading: () => null,
  ssr: false,
})

export default function ModuleContent2Blocks(props) {
  const router = useRouter()
  const { preview } = props
  const [item, setItem] = useState(props)
  const contentRef = useRef(null)
  const textColor = item?.textColor
  const bgColor = item?.backgroundColor
  const [success, setSuccess] = useState(false)
  const [videoError, setVideoError] = useState(false)
  const inViewport = useIsInViewport(contentRef)
  let pageModel = getContentModel('page')
  let contentModel = getContentModel('moduleContent2Blocks')
  const tabletScreen = Number(styleConfig.theme.screens.md.replace('px', ''))
  const mobileScreen = Number(styleConfig.theme.screens.sm.replace('px', ''))
  const textClass = textColor ? `text-color-${textColor.replace('#', '')}` : '';
  const windowDimensions = useWindowDimensions()

  let videoUrl = item?.videos?.desktop;

  if (windowDimensions.width <= tabletScreen) {
    if (windowDimensions.width <= mobileScreen) {
      videoUrl = item?.videos?.mobile
    }else{
      videoUrl = item?.videos?.tablet
    }
  }

  useEffect(()=>{
    setVideoError(false);
  }, [videoUrl])

  useEffect(async () => {
    if (preview) {
      const res = await enableContent2BlocksPreview(props, preview)
      setItem(res)
    }
  }, [])

  useEffect(() => {
    if (inViewport && props?.textAndImage && router.asPath.startsWith('/products')) {
      if (success) return
      const sucessFunction = async () => {
        try {
          let currentPath = router.asPath
          let promotionId =
            (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL) + currentPath
          promotionId = promotionId.startsWith('http') ? promotionId : 'https://' + promotionId
          //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
          let promotionName
          let creativeName = props?.textAndImage || ''
          let creativeSlot = props?.textAndImage || ''
          let locationId = props.index + 1
          if (props.product && props.product.indexValue) {
            locationId += props.product.indexValue
          }
          if (currentPath == '/') {
            promotionName = `${pageModel?.name}::${contentModel?.name}`
          } else {
            let pageType = pagePaths.find((page) => page.path == currentPath)
            promotionName = pageType?.type
              ? getContentModel(pageType?.type)?.name + `::${contentModel?.name}`
              : `${pageModel?.name}::${contentModel?.name}`
          }
          let eventData = [
            {
              promotion_id: promotionId,
              promotion_name: promotionName,
              creative_name: creativeName,
              creative_slot: creativeSlot,
              location_id: locationId,
            },
          ]

          let executed = viewContentPromotionEvent(eventData)
          if (executed) {
            setSuccess(true)
          }
        } catch (error) {
          console.error('View Promotion execution failed:', error)
        }
      }

      const intervalId = setInterval(async () => {
        if (!success) {
          await sucessFunction()
        } else {
          clearInterval(intervalId)
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [inViewport, success, router.asPath])

  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === 'blogPost') {
          return (
            <a
              href={`/blog/${node.data.target.fields.slug}`}
              onClick={() => {
                contentClick(`/blog/${node.data.target.fields.slug}`, node.data.target.fields.title)
              }}
            >
              {node.data.target.fields.title}
            </a>
          )
        } else if (node.data.target.sys.contentType.sys.id === 'modulePageDescription') {
          return <>{documentToReactComponents(node?.data?.target?.fields?.content, renderImages)}</>
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const contentType = node?.data?.target?.sys?.contentType?.sys?.id
        const content = node?.data?.target?.fields
 
        if (!content) {
          return null
        }
        if (contentType === 'moduleMarkdownText') {
          return <Markdown color={textColor}>{content.markdownText}</Markdown>
        }
      },
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return (
          <div className="prose-details-card break-words hyphens-auto">
            <p>{children}</p>
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <NextImage
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
            objectFit="cover"
            objectPosition="center"
          />
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={node.data.uri}
            onClick={(event) => {
              event.preventDefault()
              let currentPath = router.asPath
              let promotionId =
                (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL) +
                currentPath
              promotionId = promotionId.startsWith('http') ? promotionId : 'https://' + promotionId
              let linkText = node?.content[0]?.value
              //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
              let promotionName
              let creativeName = props?.textAndImage || ''
              let creativeSlot = 'Body Text::' + linkText
              let locationId = props.index + 1
              if (props.product && props.product.indexValue) {
                locationId += props.product.indexValue
              }
              if (currentPath == '/') {
                promotionName = `${pageModel?.name}::${contentModel?.name}`
              } else {
                let pageType = pagePaths.find((page) => page.path == currentPath)
                promotionName = pageType?.type
                  ? getContentModel(pageType?.type)?.name + `::${contentModel?.name}`
                  : `${pageModel?.name}::${contentModel?.name}`
              }
              let eventData = [
                {
                  promotion_id: promotionId,
                  promotion_name: promotionName,
                  creative_name: creativeName,
                  creative_slot: creativeSlot,
                  location_id: locationId,
                },
              ]
              selectContentPromotionEvent(eventData)
              router.push(node.data.uri)
            }}
          >
            {children}
          </a>
        )
      },
    },
  }

  const imageUrl = item.backgroundImage || item.backgroundImage?.url
  const altTag = item.altTag || ''

  //please don't remove commented code
  // const overlayColor =
  //   item.overlayType && item.overlayType === 'Dark'
  //     ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
  //     : 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))'

  const headlineStyle =
    item.imageSize == '1/5'
      ? `content-custom-header font-bold md:text-2xl text-xl font-bold leading-none tracking-widest ${
          textColor ? 'text-color' : 'text-black'
        } mb-[12px]`
      : `font-bold text-[24px] md:text-[28px] text-xl font-bold leading-[32px] md:leading-[36px] tracking-[0.08em] ${
          textColor ? 'text-color' : 'text-black'
        } my-3 md:my-4 max-w-[864px]`
  const bodyTextStyle =
    item.imageSize == '2/3'
      ? `text-[#4C4444] text-[14px] md:text-[16px] leading-[22px] md:leading-[26px] first-line:font-[600] tracking-[letter-spacing: 0.02em] content2Block_content ${textClass}`
      : `prose prose-details-card content2Block_content ${textClass} ${
          router.pathname == '/affiliate' || router.pathname == '/grant-program'
            ? 'prose_affiliate'
            : router.pathname == '/ecosystems-sec'
            ? 'eco-system'
            : router.pathname === '/depot-events'
            ? 'prose_depot_event'
            : ''
        }`

  const primaryCta = {
    id: item?.primaryCta?.id || null,
    label: item?.primaryCta?.label || item?.ctaLeftText,
    openInNewTab: item?.primaryCta?.openInNewTab || false,
    openVideoPlayer: item?.primaryCta?.openVideoPlayer || false,
    url: item?.primaryCta?.url || item?.ctaLeftUrl,
    bgColor: item?.primaryCta?.backgroundColor || item?.ctaLeftBgColor,
  }

  const secondaryCta = {
    id: item?.secondaryCta?.id || null,
    label: item?.secondaryCta?.label || item?.ctaRightText,
    openInNewTab: item?.secondaryCta?.openInNewTab || false,
    openVideoPlayer: item?.secondaryCta?.openVideoPlayer || false,
    url: item?.secondaryCta?.url || item?.ctaRightUrl,
    bgColor: item?.secondaryCta?.backgroundColor,
  }

  const loadImage = () => {
    return (
      <NextImage
        src={`${imageUrl}`}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        alt={`${altTag}`}
      />
    )
  }

  return (
    <>
      <div
        ref={contentRef}
        className={`
          ${!item.inset ? 'w-full' : 'container'}
          ${item.imageSize == '1/5' ? 'flex flex-col gap-[50px]' : 'grid'}
          ${router.pathname == '/wader-warranty' && 'lg:mx-[80px] xl:mx-auto'}
          ${
            item?.backgroundColor === 'white'
              ? 'bg-white mb-[30px] lg:mb-[120px]'
              : item.imageSize != '1/2'
              ? bgColor
                ? 'bg-color'
                : 'bg-gray-100'
              : bgColor
              ? 'bg-color'
              : 'bg-gray-50'
          }
          ${!item.generalPaddingDisable ? 'my-[20px] md:my-[50px]' : null}
        `}
      >
        <div
          className={`
            ${
              item.inset &&
              'sm:px-0 sm:w-[640px] md:w-[700px] lg:w-[970px] xl:w-[1280px] mx-auto'
            }
            content-horizontal flex flex-col lg:flex-row overflow-hidden
            ${
              item.imageSize == '1/5' &&
              `lg:mb-[${router.pathname == '/wader-warranty' ? '0' : '80px'}]`
            }
          `}
        >
          <div
            className={`
              w-full ${item.imageSize == '2/3' ? 'lg:h-[640px] !lg:w-2/3' : 'lg:w-1/2'}
              ${item.imageSize == '1/5' && 'hidden lg:block lg:w-1/5'}
              ${item.imagePosition == 'left' ? 'lg:order-1' : 'lg:order-2'}
              ${router.pathname == '/affiliate' && 'hidden md:block'}
              ${
                router.pathname == '/wader-warranty' &&
                '!block max-h-[245px] md:max-h-[100%] overflow-hidden md:overflow-auto'
              }
            `}
          >
            <div
              className={`
                content-horizontal__image relative h-full w-full  aspect-square bg-center bg-cover
                ${router.pathname == '/warranties' && 'max-h-[245px] md:max-h-full'}
              `}
            >
              <div className="imageBackground h-full content2_block_video">
                {videoUrl && !videoError ? (
                  <DynamicVideoPlayer
                    url={videoUrl}
                    playing={true}
                    playsinline={true}
                    loop={true}
                    volume={0}
                    muted={true}
                    width="100%"
                    height="100%"
                    onError={() => setVideoError(true)}
                  />
                ) : (
                  loadImage()
                )}
              </div>
            </div>
          </div>

          <div
            className={`
              flex flex-col lg:justify-center h-full md:mb-5 lg:mb-0
              ${
                item.imageSize == '1/5'
                  ? 'lg:w-4/5 py-[32px] md:py-[68.5px] px-[20px]'
                  : 'px-6 py-8 md:py-15 w-full lg:w-1/2'
              }
              ${item.imagePosition == 'left' ? 'lg:order-2' : 'lg:order-1 '}
              ${item.imageSize == '2/3' ? 'md:px-[58px] lg:max-w-[33.3%]' : 'md:px-[80px]'}
            `}
          >
            {item.eyebrow ? (
              <span
                className={`title text-xs md:text-xs uppercase font-semibold md:font-bold leading-[16px] md:leading-[15px] tracking-[0.1em] md:tracking-widest content2Block_content ${textClass} ${
                  textColor ? 'text-color' : 'text-black'
                }`}
              >
                {item.eyebrow}
              </span>
            ) : null}
            {item.headline ? (
              <div className={`${headlineStyle} font-bold tracking-[0.08em] uppercase content2Block_content ${textClass}`}>
                {item.headline}
              </div>
            ) : null}
            <div
              className={`list ${
                item.imageSize == '1/5' ? `content-custom-body prose max-w-none content2Block_content ${textClass}` : bodyTextStyle
              }`}
            >
              {item?.bodyText?.data
                ? documentToReactComponents(item.bodyText, renderOptions)
                : item.bodyText}
            </div>

            {primaryCta.url && primaryCta.label && (
              <div className={`${primaryCta.url ? 'flex' : ''} mt-10 md:mt-[32px]`}>
                {primaryCta.url && primaryCta.label ? (
                  <div
                    className={`${
                      !secondaryCta.url && !secondaryCta.label
                        ? 'md:w-[180px]'
                        : 'button-group-wrap flex'
                    } md:h-[56px] mr-2`}
                  >
                    <CTA
                      className={`btn btn-primary flex items-center justify-center tracking-widest py-[21px] px-[24px]`}
                      label={primaryCta.label}
                      openInNewTab={primaryCta.openInNewTab}
                      openVideoPlayer={primaryCta.openVideoPlayer}
                      url={primaryCta.url}
                      background={primaryCta.bgColor}
                      moduleProps={props}
                    />
                  </div>
                ) : null}

                {secondaryCta.url && secondaryCta.label ? (
                  <div className={`button-group-wrap flex`}>
                    <CTA
                      className={`btn btn-primary flex items-center tracking-widest py-[21px] px-[24px] md:mr-2 ${
                        item.imageSize == '2/3' && 'px-4'
                      }`}
                      label={secondaryCta.label}
                      openInNewTab={secondaryCta.openInNewTab}
                      openVideoPlayer={secondaryCta.openVideoPlayer}
                      url={secondaryCta.url}
                      background={secondaryCta.bgColor}
                      moduleProps={props}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .text-color {
            color: ${textColor} !important;
          }
          .bg-color {
            background-color: ${bgColor};
          }
          :global(.content2Block_content.${textClass} *) {
            color: ${textColor};
          }
          @media (max-width: 767px) {
            :global(h3.content-custom-header) {
              font-size: 24px !important;
              letter-spacing: 0.08em;
              line-height: 32px;
            }

            :global(.content-custom-body p) {
              font-size: 14px !important;
              line-height: 22px;
            }

            :global(.content-custom-body li > p) {
              margin-top: 0 !important;
              margin-bottom: 1.25em !important;
            }

            :global(.list.content-custom-body *) {
              font-size: 14px !important;
              line-height: 22px;
            }
          }

          :global(li p) {
            word-break: break-word;
          }

          :global(li a) {
            word-break: break-word;
          }

          :global(.list li::marker) {
            color: transparent;
          }

          :global(.list li > ol) {
            padding-left: 0;
          }

          :global(.list li > ol li > ul) {
            padding-left: 0;
          }

          :global(.list li > ul) {
            padding-left: 0;
          }

          .prose h4 {
            text-align: left;
            margin-bottom: 20px;
          }
          .prose li::marker {
            color: #000;
          }
          .prose li {
            margin: 0;
          }
          .prose a {
            text-transform: unset;
          }

          :global(.service-content-block p a) {
            display: inline-block;
            text-transform: inherit;
            letter-spacing: inherit;
          }

          .title {
            color: #4c4442;
          }
          .prose_depot_event * {
            font-size: 16px !important;
          }
          .imageBackground::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          }
          :global(.content2_block_video video) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
          }
        `}
      </style>
    </>
  )
}
